export interface IType {
  label: string
  value: string
}

export default class Types 
{
    // CONTAINER
    static FLAT_CONTAINER = { id : "flat", label : "Flat", value : "flat" }
    static SEGMENT_CONTAINER = {
        id    : "segment",
        label : "Segment",
        value : "segment",
    }

    //ANALYTICS
    static PAGE_VIEWS_ACTION_ANALYTICS = { value : "pageViews", label : "Visualizações de páginas", id : "pageViews" }
    static UNIQUE_USER_ACTION_ANALYTICS = { value : "uniqueUser", label : "Usuário Único", id : "uniqueUser" }
    static REFERRER_ACTION_ANALYTICS = { value : "referrerViews", label : "Visualizações de Origem", id : "referrerViews" }
    static DEVICE_ACTION_ANALYTICS = { value : "deviceViews", label : "Visualizações de Dispositivos", id : "deviceViews" }
    static PRODUCT_VIEWS_ACTION_ANALYTICS = { value : "productViews", label : "Visualizações de Produtos", id : "productViews" }
    static PRODUCT_CLICK_ACTION_ANALYTICS = { value : "productClicks", label : "Clicks de Produtos", id : "productClicks" }
    static VARIANT_CLICK_ACTION_ANALYTICS = { value : "variantClicks", label : "Clicks de Variantes", id : "variantClicks" }
    static SKU_CLICK_ACTION_ANALYTICS = { value : "skuClicks", label : "Clicks SKU", id : "skuClicks" }
    static CATEGORY_CLICK_ACTION_ANALYTICS = { value : "categoryClicks", label : "Clicks de Categorias", id : "categoryClicks" }
    static COLLECTION_CLICK_ACTION_ANALYTICS = { value : "collectionClicks", label : "Clicks de Coleções", id : "collectionClicks" }
    static BRAND_CLICK_ACTION_ANALYTICS = { value : "brandClicks", label : "Clicks de Marcas", id : "brandClicks" }
    static USER_LOGIN_ACTION_ANALYTICS = { value : "userLogin", label : "Login", id : "userLogin" }
    static ADD_PRODUCT_CART_ACTION_ANALYTICS = { value : "addProductCart", label : "Adicionar Produto no Carrinho", id : "addProductCart" }
    static ADD_SKU_CART_ACTION_ANALYTICS = { value : "addSkuCart", label : "Adicionar Sku no Carrinho", id : "addSkuCart" }
    static ADD_VARIANT_CART_ACTION_ANALYTICS = { value : "addVariantCart", label : "Adicionar Variant no Carrinho", id : "addVariantCart" }
    static INIT_CHECKOUT_ACTION_ANALYTICS = { value : "initCheckout", label : "Iniciar Checkout", id : "initCheckout" }
    static COUPON_CHECKOUT_ACTION_ANALYTICS = { value : "couponCheckout", label : "Cupom Checkout", id : "couponCheckout" }
    static ADDRESS_CHECKOUT_ACTION_ANALYTICS = { value : "addressCheckout", label : "Endereço Checkout", id : "addressCheckout" }
    static SHIPPING_CHECKOUT_ACTION_ANALYTICS = { value : "shippingCheckout", label : "Método de Entrega Checkout", id : "shippingCheckout" }
    static PAYMENT_METHOD_CHECKOUT_ACTION_ANALYTICS = { value : "paymentMethodCheckout", label : "Método de Pagamento Checkout", id : "paymentMethodCheckout" }
    static FINISHED_CHECKOUT_ACTION_ANALYTICS = { value : "finishedCheckout", label : "Finalizar Checkout", id : "finishedCheckout" }
    static PERIODS_DAYS_ACTION_ANALYTICS = { value : "periodsDays", label : "Períodos do dia", id : "periodsDays" }
    static HOUR_DAY_ACTION_ANALYTICS = { value : "hourDay", label : "Hora do dia", id : "hourDay" }
    static DAY_WEEK_ACTION_ANALYTICS = { value : "dayWeek", label : "Dia da Semana", id : "dayWeek" }
    static WEATHER_ACTION_ANALYTICS = { value : "weather", label : "Clima Tempo", id : "weather" }

    //DISCOUNT
    static PERCENTAGE_DISCOUNT = { value : "percentage", label : "Porcentagem", id : "percentage" }
    static FIXED_DISCOUNT = { value : "fixed", label : "Fixo", id : "fixed" }

    //MATRIX
    static COMBINE_MATRIX = { value : "combine", label : "Combinação", id : 0 }
    static SEPARATE_MATRIX = { value : "separate", label : "Separação", id : 1 }
    static VARIANT_DEFAULT = [ { items : [ { id : "_default", value : "_default" } ] } ]

    //DEPOSIT BONUS
    static FIRST_DEPOSIT_BONUS_TYPE = { value : "first", label : "Primeiro Depósito", id : "first" }
    static DEPOSIT_BONUS_GENERAL = {
        id    : "general",
        label : "Geral",
        value : "general",
    }
    static DEPOSIT_BONUS_PLUS = { id : "plus", label : "Plus", value : "plus" }
    static DEPOSIT_BONUS_SCHEDULED = {
        id    : "scheduled",
        label : "Agendado",
        value : "scheduled",
    }
    static GET_DEPOSIT_BONUSES_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/getDepositBonuses`

    // FIELD
    static TEXT_FIELD = { value : "Text" }
    static NUMBER_FIELD = { value : "Number" }
    static SELECT_FIELD = { value : "Select" }
    static LABEL_FIELD = { value : "Label" }
    static HIDDEN_FIELD = { value : "Hidden" }
    static RADIO_FIELD = { value : "Radio" }
    static ADDRESS_FIELD = { value : "Address" }
    static DATE_FIELD = { value : "Date" }
    static MATRIX_FIELD = { value : "Matrix" }
    static REFERENCE_SELECT_FIELD = { value : "ReferenceSelect" }
    static REFERENCE_MULTI_SELECT_FIELD = { value : "ReferenceMultiSelect" }
    static REFERENCE_COUPON_FIELD = { value : "ReferenceCoupon" }
    static REFERENCE_FORM_FIELD = { value : "ReferenceForm" }
    static VERIFY_PAYMENT_FIELD = { value : "VerifyPayment" }
    static PAYMENT_STATUS_FIELD = { value : "PaymentStatus" }
    static CREDIT_CARD_FIELD = { value : "CreditCard" }
    static MULTI_SELECT_FIELD = { value : "MultiSelect" }
    static SUM_FORM_FIELD = { value : "SumForm" }
    static SELECT_ICON_FIELD = { value : "SelectIcon" }
    static SUB_FORM_FIELD = { value : "SubForm" }
    static FIELDSET_FIELD = { value : "Fieldset" }
    static COUNTS_DOCUMENTS_FIELD = { value : "CountsDocuments" }
    static LOCK_FIELD = { value : "Lock" }
    static IMAGE_FIELD = { value : "Image" }
    static AVATAR_FIELD = { value : "Avatar" }
    static TITLE_FIELD = { value : "Title" }
    static TIME_FIELD = { value : "Time" }
    static FUNCTION_FIELD = { value : "Function" }
    static BUTTON_FIELD = { value : "Button" }
    static SHARE_BUTTONS_FIELD = { value : "ShareButtons" }
    static LAST_DOCUMENT_FIELD = { value : "LastDocument" }
    static CENTAVOS_FIELD = { value : "Centavos" }
    static CURRENCY_FIELD = { value : "Currency" }
    static URL_FIELD = { value : "Url" }
    static VIDEO_URL_FIELD = { value : "VideoUrl" }
    static DOWNLOAD_FIELD = { value : "Download" }
    static SUM_FIELDSET_FIELD = { value : "SumFieldset" }
    static TOGGLE_FIELD = { value : "Toggle" }
    static BARCODE_FIELD = { value : "Barcode" }
    static PERCENTAGE_FIELD = { value : "Percentage" }
    static COMMENT_FIELD = { value : "Comment" }
    static STEP_FIELD = { value : "Step" }
    static BIRTHDAY_FIELD = { value : "Birthday" }
    static MULTI_CHECKBOX_FIELD = { value : "MultiCheckbox" }
    static EMAIL_FIELD = { value : "Email" }
    static INTEGER_FIELD = { value : "Integer" }
    static TEXTAREA_FIELD = { value : "Textarea" }
    static PASSWORD_FIELD = { value : "Password" }
    static CONFIRM_PASSWORD_FIELD = { value : "ConfirmPassword" }
    static UPLOAD_JSON_FIELD = { value : "UploadJson" }
    static ZIPCODE_FIELD = { value : "Zipcode" }
    static QUESTION_FIELD = { value : "Question" }
    static ALNUM_FIELD = { value : "Alnum" }
    static PHONE_FIELD = { value : "Phone" }
    static CPF_FIELD = { value : "CPF" }
    static CPFCNPJ_FIELD = { value : "CPFCNPJ" }
    static ITEMS_SETTING_FIELD = { value : "ItemsSetting" }
    static CNPJ_FIELD = { value : "CNPJ" }

    // form
    static GET_FORM_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/formApi/getForm3/`
    static COLLECTION_FORM_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/formApi/collectionForm3`

    // GRID
    static GET_GRID_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/gridApi/getGrid`

    // PAGARME
    static ADD_PAYMENT_PAGARME_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/pagarmeApi/addPayment/`
    static ADD_CARD_PAGARME_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/pagarmeApi/addCard/`
    static GET_INSTALLMENTS_PAGARME_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/pagarmeApi/getInstallments/`

    // UTIL
    static RSS_UTIL_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/utilApi/rssParser`
    static CLEAR_CACHE_BY_TAG_UTIL_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/utilApi/clearCacheByTag`
    static PROXY_UTIL_SERVER        = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/utilApi/proxy`
    static PDF_PROXY_UTIL_SERVER    = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/utilApi/pdfProxy`
    static GET_TIMEZONE_UTIL_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/utilApi/getTimezone`
    static SET_TIMEZONE_UTIL_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/utilApi/setTimezone`
    static TRANSLATE_UTIL_SERVER    = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/utilApi/translate`

    // NF
    static ADD_AUTHENTICATE_NF_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/nfApi/addAuthenticate/`
    static UPDATE_NF_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/managersaasApi/nfe-query`
    static ISSUE_NF_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/nfApi/reemitNFs`
    static CANCEL_NF_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/managersaasApi/cancelNFE`

    // LESSON
    static GET_LESSON_BY_USER_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/eadApi/getLessonsByUser/`

    // E-MAIL
    static SEND_TRANSECTION_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/emailApi/sendTransection/`

    // PRINT
    static PRINT_ORDER_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/printApi/order/`
    static PRINT_REPORT_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/printApi/reportGraphic/`

    // ANALYTICS
    static PUSH_ANALYTICS_SERVER = `${process.env.NEXT_PUBLIC_ANALYTICS_SOURCE_URL}/analyticsApi/push/`
    static WEATHER_SERVER = `${process.env.NEXT_PUBLIC_ANALYTICS_SOURCE_URL}/weatherApi/today/`

    //CNPJ WS
    static CNPJWS_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cnpjwsApi/check/`

    // FAVORITE
    static COLLECTION_PRODUCT_VARIANT_FAVORITE_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/favoriteApi/collectionProductVariantCollection/`
    static COLLECTION_FAVORITE_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/favoriteApi/collection/`
    static GET_FAVORITE_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/favoriteApi/get/`
    static SET_FAVORITE_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/favoriteApi/set/`

    // COMMENT
    static ADD_COMMENT_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/commentApi/add/`

    // AUTH
    static GET_TOKEN_LOGIN_AUTH_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/authV2/getTokenLogin/`
    static LOGIN_AUTH_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/authV2/login/`
    static LOGOUT_AUTH_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/authV2/logout/`
    static GET_LOGGED_AUTH_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/authV2/getLogged/`
    static IS_LOGGED_AUTH_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/authV2/isLogged/`
    static VERIFY_LOGIN_AUTH_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/authV2/verifyLogin/`
    static ADD_USER_AUTH_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/authV2/addUser/`
    static ADD_USER_WITH_TOKEN_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/authV2/addUserWithToken/`
    static SET_USER_AUHT_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/authV2/setUser/`
    static GET_USER_AUHT_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/authV2/getUser/`
    static RECOVERY_PASSWORD_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/authV2/recoveryPassword/`
    static GET_AUTH_TOKEN_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/authV2/getAuthToken/`
    static UNBLOCKED = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/authV2/unblocked/`

    // CASHIER
    static CASHIER_COLLECTION_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cashierApi/collection/`
    static LAST_CASHIER_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cashierApi/last/`
    static CASHIER_MOVEMENT_ADD_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cashierApi/add/`

    // DOCUMENT
    static COLLECTION_DOCUMENT_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/documentV2/collection/`
    static COUNT_DOCUMENT_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/documentV2/count/`
    static COUNT_OWNER_DOCUMENT_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/documentV2/countOwner/`
    static COLLECTION_OWNER_DOCUMENT_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/documentV2/collectionOwner/`
    static GET_DOCUMENT_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/documentV2/get/`
    static GET_DOCUMENT_FRONT = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/documentV2/get/`
    static ADD_DOCUMENT_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/documentV2/add/`
    static SET_DOCUMENT_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/documentV2/set/`
    static INCREMENT_DOCUMENT_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/documentV2/increment/`
    static SET_VIEWS_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/documentV2/setViews/`
    static DELETE_DOCUMENT_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/documentV2/del/`
    static UPLOAD_STORAGE_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/storageV2/uploadTemp/`
    static UPLOAD_MAKE_PUBLIC_STORAGE_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/storageV2/uploadMakePublic/`
    static FLAT_DOCUMENT_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/documentV2/flat/`
    static CLEAR_CACHE_SERVER  = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/documentV2/clearCache/`

    static GET_ACCOUNT_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/accountV2/get/`
    static CALCULATE_FREE_SHIPPING_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/shippingApi/calculateFreeShipping/`
    static CALCULATE_SHIPPING_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/shippingApi/calculateShippings/`
    static GET_TRACK_CORREIOS_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/correiosApi/track/`

    //ORDER
    static COLLECTION_ORDER_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/orderApi/collection/`
    static GET_ORDER_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/orderApi/get/`
    static ADD_PAYMENT_ORDER_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/orderApi/addPayment/`
    static SET_PAYMENT_METHOD_ORDER_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/orderApi/setPaymentMethod/`
    static SET_INSTALLMENT_ORDER_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/orderApi/setInstallment/`
    static SET_CREDIT_CARD_ORDER_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/orderApi/setCreditCard/`
    static SET_CREDIT_CARD_REFERENCE_ORDER_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/orderApi/setCreditCardReference/`
    static SET_ITEM_ORDER_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/orderApi/setItem/`
    static APPROVED_ORDER_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/orderApi/approved/`
    static CANCELED_ORDER_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/orderApi/canceled/`
    static SET_STATUS_BY_STORE_ORDER_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/orderApi/setSatusByStore/`
    static SPLIT_BY_STORE_ORDER_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/orderApi/splitByStore/`
    static ADD_REORDER_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/orderApi/addReorder/`
    static UPDATE_CART_ORDER_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/orderApi/updateCartOrder`
    static CLOSURE_PDV_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/orderApi/closurePDV`
    static NEW_STATUS_ORDER_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/orderApi/newStatus`
    static AWAITING_STATUS_ORDER_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/orderApi/awaitingStatus`
    static PAID_STATUS_ORDER_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/orderApi/paidStatus`
    static SET_CLIENT_ORDER_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/orderApi/setClientOrder`
    static SET_PAYMENT_STATUS_ORDER_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/orderApi/setPaymentStatusOrder`

    // PRODUCT 2
    static COLLECTION_PRODUCT_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/productApi/collection/`
    static COLLECTION_PRODUCT_VARIANT_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/productApi/collectionProductVariant/`
    static GET_PRODUCT_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/productApi/get/`
    static GET_STOCK_TABLE_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/productApi/getStockTable/`

    //BET TYPES
    static SPORT_BET_TYPE = { id : "A6lnToyU", label : "Esporte", value : "sport" }
    static PARSING_CASINO_BET_TYPE = { id : "qTvRL02O", label : "Parsing Casino", value : "minigame" }
    static MINIGAME_BET_TYPE = { id : "wnoVO56f", label : "Minigame", value : "mini" };
    static LIVESPORT_BET_TYPE = { id : "rikwUJ4y", label : "Esporte Live", value : "liveSport" }

    // BACCARAT
    static MINIGAME_BACCARAT_P = { id : "player", label : "Player", value : "player" }
    static MINIGAME_BACCARAT_B = { id : "banker", label : "Banker", value : "banker" }
    static MINIGAME_BACCARAT_T = { id : "tie", label : "Tie", value : "tie" }
    static MINIGAME_BACCARAT_PP = { id : "pair", label : "Par", value : "pair" }
    static MINIGAME_BACCARAT_BB = { id : "pair", label : "Par", value : "pair" }

    //ODD EVEN
    static MINIGAME_ODDOREVEN_ODD = { id : "odd", label : "Impar", label_kr : "홀", value : "value" }
    static MINIGAME_ODDOREVEN_EVEN = { id : "even", label : "Par", label_kr : "짝", value : "value" }
    static MINIGAME_ODD_EVEN_OVER = { id : "over", label : "Acima", label_kr : "Over", value : "value" }
    static MINIGAME_ODD_EVEN_UNDER = { id : "under", label : "Abaixo", label_kr : "Under", value : "value" }
    static MINIGAME_ODD_EVEN_BLACK = { id : "black", label : "Preto", label_kr : "Black", value : "value" }
    static MINIGAME_ODD_EVEN_RED = { id : "red", label : "Vermelho", label_kr : "Red", value : "value" }
    static MINIGAME_ODD_EVEN_CLUBS = { id : "clubs", label : "Paus", label_kr : "Clubs", value : "value" }
    static MINIGAME_ODD_EVEN_DIAMONDS = { id : "diamonds", label : "Ouros", label_kr : "diamonds", value : "value" }
    static MINIGAME_ODD_EVEN_HEARTS = { id : "hearts", label : "Copas", label_kr : "hearts", value : "value" }
    static MINIGAME_ODD_EVEN_SPADES = { id : "spades", label : "Espadas", label_kr : "spades", value : "value" }
    static MINIGAME_ODDOREVEN_C = { id : "canceled", label : "Cancelado", value : "value" }
    static MINIGAME_ODD_EVEN_ODD = { id : "odd", label : "Impar", label_kr : "홀", value : "value" }
    static MINIGAME_ODD_EVEN_EVEN = { id : "even", label : "Par", label_kr : "짝", value : "value" }
    static MINIGAME_ODD_EVEN_C = { id : "canceled", label : "Cancelado", value : "value" }

    // LADDER
    static MINIGAME_LADDER_1MIN_LEFT = { id : "Left", label : "Esquerda", label_kr : "좌", value : "value" }
    static MINIGAME_LADDER_1MIN_RIGHT = { id : "Right", label : "Direita", label_kr : "우", value : "value" }
    static MINIGAME_LADDER_1MIN_3 = { id : "3", label : "3", label_kr : "3", value : "value" }
    static MINIGAME_LADDER_1MIN_4 = { id : "4", label : "4", label_kr : "4", value : "value" }
    static MINIGAME_LADDER_1MIN_ODD = { id : "Odd", label : "Ímpar", label_kr : "홀", value : "value" }
    static MINIGAME_LADDER_1MIN_EVEN = { id : "Even", label : "Par", label_kr : "짝", value : "value" }
    static MINIGAME_LADDER_1MIN_LEFT_3_EVEN = { id : "Left_3_Even", label : "Esquerda + 3 + par", label_kr : "좌 + 3 + 짝", value : "value" }
    static MINIGAME_LADDER_1MIN_RIGHT_3_ODD = { id : "Right_3_Odd", label : "Direita + 3 + ímpar", label_kr : "우 + 3 + 홀", value : "value" }
    static MINIGAME_LADDER_1MIN_LEFT_4_ODD = { id : "Left_4_Odd", label : "Esquerda + 4 + ímpar", label_kr : "좌 + 4 + 홀", value : "value" }
    static MINIGAME_LADDER_1MIN_RIGHT_4_EVEN = { id : "Right_4_Even", label : "Direita + 4 + par", label_kr : "우 + 4 + 짝", value : "value" }

    static MINIGAME_LADDER_2MIN_LEFT = { id : "Left", label : "Esquerda", label_kr : "좌", value : "value" }
    static MINIGAME_LADDER_2MIN_RIGHT = { id : "Right", label : "Direita", label_kr : "우", value : "value" }
    static MINIGAME_LADDER_2MIN_3 = { id : "3", label : "3", label_kr : "3", value : "value" }
    static MINIGAME_LADDER_2MIN_4 = { id : "4", label : "4", label_kr : "4", value : "value" }
    static MINIGAME_LADDER_2MIN_ODD = { id : "Odd", label : "Ímpar", label_kr : "홀", value : "value" }
    static MINIGAME_LADDER_2MIN_EVEN = { id : "Even", label : "Par", label_kr : "짝", value : "value" }
    static MINIGAME_LADDER_2MIN_LEFT_3_EVEN = { id : "Left_3_Even", label : "Esquerda + 3 + par", label_kr : "좌 + 3 + 짝", value : "value" }
    static MINIGAME_LADDER_2MIN_RIGHT_3_ODD = { id : "Right_3_Odd", label : "Direita + 3 + ímpar", label_kr : "우 + 3 + 홀", value : "value" }
    static MINIGAME_LADDER_2MIN_LEFT_4_ODD = { id : "Left_4_Odd", label : "Esquerda + 4 + ímpar", label_kr : "좌 + 4 + 홀", value : "value" }
    static MINIGAME_LADDER_2MIN_RIGHT_4_EVEN = { id : "Right_4_Even", label : "Direita + 4 + par", label_kr : "우 + 4 + 짝", value : "value" }

    static MINIGAME_LADDER_3MIN_LEFT = { id : "Left", label : "Esquerda", label_kr : "좌", value : "value" }
    static MINIGAME_LADDER_3MIN_RIGHT = { id : "Right", label : "Direita", label_kr : "우", value : "value" }
    static MINIGAME_LADDER_3MIN_3 = { id : "3", label : "3", label_kr : "3", value : "value" }
    static MINIGAME_LADDER_3MIN_4 = { id : "4", label : "4", label_kr : "4", value : "value" }
    static MINIGAME_LADDER_3MIN_ODD = { id : "Odd", label : "Ímpar", label_kr : "홀", value : "value" }
    static MINIGAME_LADDER_3MIN_EVEN = { id : "Even", label : "Par", label_kr : "짝", value : "value" }
    static MINIGAME_LADDER_3MIN_LEFT_3_EVEN = { id : "Left_3_Even", label : "Esquerda + 3 + par", label_kr : "좌 + 3 + 짝", value : "value" }
    static MINIGAME_LADDER_3MIN_RIGHT_3_ODD = { id : "Right_3_Odd", label : "Direita + 3 + ímpar", label_kr : "우 + 3 + 홀", value : "value" }
    static MINIGAME_LADDER_3MIN_LEFT_4_ODD = { id : "Left_4_Odd", label : "Esquerda + 4 + ímpar", label_kr : "좌 + 4 + 홀", value : "value" }
    static MINIGAME_LADDER_3MIN_RIGHT_4_EVEN = { id : "Right_4_Even", label : "Direita + 4 + par", label_kr : "우 + 4 + 짝", value : "value" }

    //POWER BALL
    static MINIGAME_POWERBALL_ODD = { id : "Odd", label : "Ímpar", label_kr : "홀", value : "value" }
    static MINIGAME_POWERBALL_POWERODD = { id : "PowerOdd", label : "Power Ball Ímpar", label_kr : "Power Ball Odd", value : "value" }
    static MINIGAME_POWERBALL_EVEN = { id : "Even", label : "Par", label_kr : "짝", value : "value" }
    static MINIGAME_POWERBALL_POWEREVEN = { id : "PowerEven", label : "Power Ball Par", label_kr : "Power Ball Even", value : "value" }
    static MINIGAME_POWERBALL_UNDER = { id : "Under", label : "Abaixo", label_kr : "언더", value : "value" }
    static MINIGAME_POWERBALL_POWERUNDER = { id : "PowerUnder", label : "Power Ball Abaixo", label_kr : "Power Ball Under", value : "value" }
    static MINIGAME_POWERBALL_OVER = { id : "Over", label : "Acima", label_kr : "오버", value : "value" }
    static MINIGAME_POWERBALL_POWEROVER = { id : "PowerOver", label : "Power Ball Acima", label_kr : "Power Ball Over", value : "value" }
    static MINIGAME_POWERBALL_HIGH = { id : "High", label : "Alto", label_kr : "High", value : "value" }
    static MINIGAME_POWERBALL_MEDIUM = { id : "Medium", label : "Médio", label_kr : "Medium", value : "value" }
    static MINIGAME_POWERBALL_LOW = { id : "Low", label : "Baixo", label_kr : "Low", value : "value" }

    static PARTNER_WITHDRAWAL_POINTS_REWARDS = { id : "partnerWithdrawal", label : "Saque", value : "partnerWithdrawal" }
    static PARTNER_CANCEL_WITHDRAWAL_POINTS_REWARDS = { id : "partnerCancelWithdrawal", label : "Saque Cancelado", value : "partnerCancelWithdrawal" }
    static POINTS_TRANSFER_POINTS_REWARDS = { id : "pointsTransfer", label : "Transferência de Pontos", value : "pointsTransfer" }

    //BET
    static RESET_BET_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/resetBet/`
    static RESET_BET_ITEM_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/resetBetItem/`
    static RESET_MINIGAME_BET_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/resetMinigameBet/`
    static CANCEL_BET_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/cancelBet/`
    static SETTLE_BET_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/settleBet`
    static APPROVE_DEPOSIT_BET_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/approveDeposit/`
    static ROLLBACK_DEPOSIT_BET_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/rollbackDeposit/`
    static REJECT_DEPOSIT_BET_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/rejectDeposit/`
    static WITHDRAW_BET_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/withdraw/`
    static WITHDRAW_PARTNER_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/partnerWithdraw/`
    static DEPOSIT_BET_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/deposit/`
    static TRANSFER_BET_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/transfer/`
    static USER_PAYBACK_SERVER  = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/getUserPayback`
    static PAYBACK_WITHDRAW_SERVER  = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/paybackWithdraw`
    static POINTS_TRANSFER_BET_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/transferPoints/`
    static PROCESS_TRANSFER_BET_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/processTransfer/`
    static TODAY_STATISTICS_BET_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/todayStatistics/`
    static START_PROCESSING_TRANSFER_BET_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/startProcessingUserTransfer`
    static START_PROCESSING_WITHDRAWAL_BET_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/startProcessingUserWithdrawal`
    static CALCULATE_DEPOSIT_PARTNER_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/calculateDepositPartner/`
    static APPROVE_WITHDRAWAL_BET_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/approveWithdrawal/`
    static CANCEL_WITHDRAWAL_BET_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/cancelWithdrawal/`
    static CANCEL_WITHDRAWAL_PARTNER_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/cancelPartnerWithdrawal/`
    static HIDE_HISTORY_BET_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/hideHistory/`
    static GET_BET_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betCart/getCart/`
    static GET_BET_STATISTICS_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/getBetStatisctics/`
    static GET_BET_PROFIT_CHART_DATA_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/getProfitChartData/`
    static AUTHORIZE_BET_USER_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/authorizeUser/`
    static UNAUTHORIZE_BET_USER_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/unauthorizeUser/`
    static VERIFY_MINIGAME_BET_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/verifyMinigameBet`
    static SETTLE_MINIGAME_BET_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/settleMinigameBet`
    static COMPLETE_TURNOVER_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/completeTurnover`
    static DIRECT_ADD_POINTS_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/directAddPoints`
    static PROCESS_WITHDRAW_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/processWithdrawal`
    static APPROVE_PAYBACK_WITHDRAW_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/approvePaybackWithdrawal`
    static CANCEL_PAYBACK_WITHDRAW_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/rejectPaybackWithdrawal`
    static PROCESS_DIRECT_POINTS_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/processDirectPoints`
    static CREATE_SUBPARTNER_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/createSubpartner`
    static PARTNER_COMMISION_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/partnerCommision`
    static ADD_CASINO_GAME_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/addCasinoGame`
    // static SET_CLIENT_CART_SERVER 	 		 	= `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betCart/setClientCart/`;
    // static SET_REFERRER_CART_SERVER 	 		= `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betCart/setReferrerCart/`;
    // static SET_SELLER_CART_SERVER 	 		 	= `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betCart/setSellerCart/`;
    // static SET_DISCOUNT_CART_SERVER 	 		= `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betCart/setDiscountCart/`;
    static SET_ITEM_BET_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betCart/setItemCart/`
    static SET_MINIGAME_ITEM_BET_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betCart/setMinigameCart/`
    static SET_BONUS_BET_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betCart/setBetBonusCart/`
    static CHECKOUT_BET_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betCart/checkoutCart/`
    static SET_TOTAL_BET_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betCart/setTotalCart/`
    static RELOAD_BET_CART_ITEMS_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betCart/reloadItems/`
    static CLEAR_BET_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betCart/clearCart/`
    // static SET_GIFT_CART_SERVER 	 		 	= `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betCart/setGiftCart/`;
    // static SET_PACKAGING_CART_SERVER 	 		= `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betCart/setPackagingCart/`;
    // static SET_ITEMS_CART_SERVER 	 		 	= `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betCart/setItemsCart/`;
    static DEL_ITEM_BET_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betCart/delItemCart/`
    static DEL_BONUS_BET_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betCart/delBetBonusCart/`
    static SET_FIXTURE_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/lsportsApi/setFixtures`
    // static DEL_GIFT_CART_SERVER 	 		 	= `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betCart/delGiftCart/`;
    // static DEL_ITEMS_BY_STORE_CART_SERVER 	 	= `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betCart/delItemsByStoreCart/`;
    // static SET_SHIPPING_METHOD_CART_SERVER   	= `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betCart/setShippingCart/`;
    // static SET_PAYMENT_METHOD_CART_SERVER	 	= `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betCart/setPaymentMethodCart/`;
    // static SET_CREDITCARD_REFERENCE_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betCart/setCreditCardReferenceCart/`;
    // static SET_CREDITCARD_CART_SERVER 			= `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betCart/setCreditCardCart/`;
    // static SET_ADDRESS_REFERENCE_CART_SERVER 	= `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betCart/setAddressReferenceCart/`;
    // static SET_ADDRESS_CART_SERVER 				= `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betCart/setAddressCart/`;
    // static SET_ATTACHMENT_CART_SERVER 		 	= `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betCart/setAttachmentCart/`;
    // static SET_INSTALLMENT_CART_SERVER 	 	 	= `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betCart/setInstallmentCart/`;
    // static SET_INSTALLMENT_METHODS_SERVER		= `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betCart/setInstallmentMethods/`;

    //MESSAGE
    static MESSAGE_ANSWER_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/betApi/answerMessage/`

    //CART
    static GET_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/getCart/`
    static SET_CLIENT_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/setClientCart/`
    static SET_REFERRER_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/setReferrerCart/`
    static SET_UTM_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/setUTMCart/`
    static SET_SELLER_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/setSellerCart/`
    static SET_DISCOUNT_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/setDiscountCart/`
    static SET_ITEM_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/setItemCart/`
    static SET_GIFT_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/setGiftCart/`
    static SET_PACKAGING_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/setPackagingCart/`
    static SET_ITEMS_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/setItemsCart/`
    static DEL_ITEM_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/delItemCart/`
    static DEL_GIFT_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/delGiftCart/`
    static DEL_ITEMS_BY_STORE_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/delItemsByStoreCart/`
    static DEL_ITEMS_BY_PRODUCT_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/delItemsByProductCart/`
    static SET_SHIPPING_METHOD_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/setShippingCart/`
    static SET_PAYMENT_METHOD_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/setPaymentMethodCart/`
    static SET_CREDITCARD_REFERENCE_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/setCreditCardReferenceCart/`
    static SET_CREDITCARD_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/setCreditCardCart/`
    static SET_ADDRESS_REFERENCE_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/setAddressReferenceCart/`
    static SET_ADDRESS_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/setAddressCart/`
    static SET_ATTACHMENT_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/setAttachmentCart/`
    static SET_INSTALLMENT_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/setInstallmentCart/`
    static SET_INSTALLMENT_METHODS_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/setInstallmentMethods/`

    static SET_STORE_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/setStoreCart/`
    static CLEAR_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/clearCart/`
    static CHECKOUT_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/checkoutCart/`
    static SET_COUPON_CART = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/setCouponCart/`
    static SET_SELLER_CART = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/setSellerCart/`
    static DEL_COUPON_CART = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/delCouponCart/`
    static SET_DISCOUNT_CLIENT_CART = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/setDiscountClientCart/`
    static DEL_DISCOUNT_CLIENT_CART = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/delDiscountClientCart/`
    static GET_CART_ITEMS_QUANTITY_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/countItemsCart/`
    static VALIDATE_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/validateCart/`
    static GET_GLOBAL_CONTEXT_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/getGlobalContext/`
    static CALCULATE_ZIP_CODE_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/calculateZipcodeCart/`
    static MERGE_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/mergeCart/`
    static MERGE_ALL_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/mergeAllCart/`

    // cart reorder
    static SET_ITEM_REORDER_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/setItemReorderCart`
    static DEL_ITEM_REORDER_CART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/cartV2/delItemReorderCart`

    //chart
    static BAR_CHART_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/chartApi/bar`

    //points & rewards
    static ADD_POINTS_BY_REORDER_TOTAL_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/pointsRewardsApi/addPointsByReorderTotal`
    static ADD_POINTS_BY_DOCUMENT = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/pointsRewardsApi/addPointsByDocument`

    static DIRECT_POINTS_REWARDS = { id : "direct", label : "Modificação Direta", value : "direct" }

    //wallet
    static ADD_WALLET_BY_DOCUMENT = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/wallet/addPointsByDocument`
    static ADD_WALLET = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/wallet/addPoints`
    
    //WALLETS
    static SPORT_WALLET_DESTINATION = { label : "Esporte", label_kr : "스포츠", value : "sport", id : "BufhEGMc" }
    static CASINO_WALLET_DESTINATION = { label : "Cassino", label_kr : "카지노", value : "casino", id : "THGoiuoI" }
    static BALANCE_WALLET_DESTINATION = { label : "Saldo", label_kr : "잔액", value : "balance", id : "balance" }

    static WALLETS_LIST = [ this.SPORT_WALLET_DESTINATION, this.CASINO_WALLET_DESTINATION ]

    // 
    static XIMAX2_COLLECT_ACCOUNT_BALANCE_ALL_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/ximax2/collectAccountGameBalanceAll`
    static XIMAX2_GET_ACCOUNT_BALANCE_ALL_WITH_TIMEOUT_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/ximax2/getAccountBalanceAllWithTimeout`
    static XIMAX2_CREATE_ACCOUNT_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/ximax2/createAccount`
    static XIMAX2_GET_GAME_URL_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/ximax2/getGameUrl`
    static XIMAX2_ADD_MEMBER_POINT_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/ximax2/addMemberPoint`
    static XIMAX2_SUBSTRACT_MEMBER_POINT_SERVER = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/ximax2/subtractMemberPoint` //nunca usar All
    static XIMAX2_IMPORT_USER_TRANSACTIONS = `${process.env.NEXT_PUBLIC_API_SOURCE_URL}/ximax2/importUserTransactions`
}
